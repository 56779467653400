@import 'vars';

/** ERROR-PAGE **/
.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: $creamColor;
  margin-bottom: -136px;
  &__wrapper {
    max-width: 1528px;
    margin: auto;
  }
  &__content {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
  }
  &__title {
    font-size: 176px;
    line-height: 176px;
    color: $orangeColor;
  }
  &__message {
    text-transform: uppercase;
    margin: 40px 0;
  }
  &__text {
    font-weight: 500;
  }
  &__list-item {
    &::marker {
      color: $orangeColor;
    }
  }
  &__link {
    color: $orangeColor;
    font-weight: 500;
    opacity: 0.8;
    transition: opacity 0.2s;
    &:hover {
      opacity: 1;
    }
  }
}
/** ERROR-PAGE END **/